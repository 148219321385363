<template>
  <div>
    <div class="row align-items-center mb-4">
      <div class="col-md-6">
        <h2 class="text-dark d-inline-block m-0">Withdrawals</h2>
      </div>
      <div class="col-md-6">
        <div class="float-right"></div>
      </div>
    </div>

    <div>
      <SectionLoading v-if="loading" text="All Withdrawals loading..." />
      <div v-else>
        <!-- <div
          v-if="contents && contents.data && contents.data.length == 0"
          class="text-center my-5"
        >
          <p class="text-dark mt-5 pt-5">No Withdrawals data available yet.</p>
        </div> -->
        <div>
          <div class="card siteCard">
            <vs-tabs>
              <vs-tab label="Requests">
                <div class="table-responsive py-2">
                  <vs-table
                    id="div-with-loading"
                    max-items="10"
                    stripe
                    :data="contentsRequests.data"
                  >
                    <template slot="thead">
                      <vs-th class="text-dark" scope="col">S/N</vs-th>
                      <vs-th class="text-dark" scope="col">Date</vs-th>
                      <vs-th class="text-dark" scope="col">Last Update</vs-th>
                      <vs-th class="text-dark" scope="col">Artist</vs-th>
                      <vs-th class="text-dark" scope="col">Amount</vs-th>
                      <vs-th class="text-dark" scope="col">Status</vs-th>
                      <vs-th class="text-dark" scope="col">Action</vs-th>
                    </template>

                    <template slot-scope="{ data }">
                      <vs-tr
                        :key="indextr"
                        v-for="(withdrawal, indextr) in data"
                      >
                        <td>
                          <p class="text-dark mb-0 small">{{ indextr + 1 }}</p>
                        </td>

                        <td>
                          <div class="font-weight-bold">
                            <p class="text-dark mb-0 title">
                              {{
                                moment(
                                  new Date(withdrawal.created_at)
                                ).fromNow()
                              }}
                            </p>
                          </div>
                        </td>

                        <td>
                          <div class="font-weight-bold">
                            <p class="text-dark mb-0 title">
                              {{
                                moment(
                                  new Date(withdrawal.updated_at)
                                ).fromNow()
                              }}
                            </p>
                          </div>
                        </td>

                        <td>
                          <div class="font-weight-bold">
                            <router-link
                              v-if="withdrawal.user"
                              :to="`/artists/${withdrawal.user.uuid}`"
                              class="text-dark mb-0 title align-items-center d-flex"
                            >
                              <vs-avatar
                                :src="withdrawal.user.profile_photo_url"
                              />
                              <span class="pl-2">
                                {{ withdrawal.user.artist_name }}</span
                              >
                            </router-link>
                          </div>
                        </td>

                        <td>
                          <div class="font-weight-bold">
                            <p class="text-dark mb-0 title">
                              {{
                                withdrawal.amount
                                  | currency(
                                    getCurrencySymbol(withdrawal.currency),
                                    0.0
                                  )
                              }}
                            </p>
                          </div>
                        </td>
                        <td>
                          <span class="badge badge-warning">
                            {{ withdrawal.status }}
                          </span>
                        </td>

                        <td>
                          <button
                            @click="confirmApproval(withdrawal)"
                            class="btn btn-sm text-white px-3"
                            style="background-color: #299e29 !important"
                          >
                            Approve
                          </button>

                          <button
                            @click="confirmDecline(withdrawal)"
                            class="btn btn-sm text-white px-3"
                            style="background-color: #a90505 !important"
                          >
                            Decline
                          </button>
                        </td>
                      </vs-tr>
                    </template>
                  </vs-table>
                </div>
              </vs-tab>

              <vs-tab label="Transactions">
                <div class="table-responsive py-2">
                  <vs-table
                    id="div-with-loading"
                    max-items="10"
                    stripe
                    :data="contents.data"
                  >
                    <template slot="thead">
                      <vs-th class="text-dark" scope="col">S/N</vs-th>
                      <vs-th class="text-dark" scope="col">Date</vs-th>
                      <vs-th class="text-dark" scope="col">Last Update</vs-th>
                      <vs-th class="text-dark" scope="col">Artist</vs-th>
                      <vs-th class="text-dark" scope="col">Amount</vs-th>
                      <vs-th class="text-dark" scope="col">Status</vs-th>
                    </template>

                    <template slot-scope="{ data }">
                      <vs-tr
                        :key="indextr"
                        v-for="(withdrawal, indextr) in data"
                      >
                        <td>
                          <p class="text-dark mb-0 small">{{ indextr + 1 }}</p>
                        </td>

                        <td>
                          <div class="font-weight-bold">
                            <p class="text-dark mb-0 title">
                              {{
                                moment(
                                  new Date(withdrawal.created_at)
                                ).fromNow()
                              }}
                            </p>
                          </div>
                        </td>

                        <td>
                          <div class="font-weight-bold">
                            <p class="text-dark mb-0 title">
                              {{
                                moment(
                                  new Date(withdrawal.updated_at)
                                ).fromNow()
                              }}
                            </p>
                          </div>
                        </td>

                        <td>
                          <div class="font-weight-bold">
                            <router-link
                              v-if="withdrawal.user"
                              :to="`/artists/${withdrawal.user.uuid}`"
                              class="text-dark mb-0 title align-items-center d-flex"
                            >
                              <vs-avatar
                                :src="withdrawal.user.profile_photo_url"
                              />
                              <span class="pl-2">
                                {{ withdrawal.user.artist_name }}</span
                              >
                            </router-link>
                          </div>
                        </td>

                        <td>
                          <div class="font-weight-bold">
                            <p class="text-dark mb-0 title">
                              {{
                                withdrawal.amount
                                  | currency(
                                    getCurrencySymbol(withdrawal.currency),
                                    0.0
                                  )
                              }}
                            </p>
                          </div>
                        </td>
                        <td>
                          <span class="badge badge-success">
                            {{ withdrawal.status }}
                          </span>
                        </td>
                      </vs-tr>
                    </template>
                  </vs-table>
                </div>
              </vs-tab>
            </vs-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SectionLoading from "../../../components/SectionLoading";

export default {
  data() {
    return {
      loading: false,
      contents: {
        data: [],
      },
      contentsRequests: {
        data: [],
      },
    };
  },
  mounted() {
    this.getWithdrawalsRequests();
    this.getWithdrawalsTransactions();
  },
  components: {
    SectionLoading,
  },
  methods: {
    getCurrencySymbol(currency) {
      switch (currency.toLowerCase()) {
        case "ngn":
          return "₦";
        case "usd":
          return "$";
        case "eur":
          return "€";
        case "gbp":
          return "£";
        default:
          return "₦";
      }
    },
    confirmApproval(withdrawal) {
      this.$vs.dialog({
        type: "confirm",
        color: "success",
        title: `Confirm Approval`,
        text: `Are you sure you want to approve this Withdrawal ?`,
        accept: this.approveWithdrawal,
      });

      this.activeData = withdrawal;
    },

    confirmDecline(withdrawal) {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm Decline`,
        text: `Are you sure you want to decline this Withdrawal ?`,
        accept: this.declineWithdrawal,
      });

      this.activeData = withdrawal;
    },

    declineWithdrawal() {
      const withdrawal = this.activeData;
      this.$vs.loading();

      let fetch = {
        path: `admin/withdrawal/decline-transaction/${withdrawal.id}`,
      };

      this.$store
        .dispatch("postRequest", fetch)
        .then((resp) => {
          this.$vs.loading.close();
          this.$toast.success(
            "Withdrawal Request Approved!",
            "Success",
            this.$toastPosition
          );

          this.getWithdrawalsRequests();
          this.getWithdrawalsTransactions();
        })
        .catch((err) => {
          this.$vs.loading.close();

          if (err.response) {
            this.$toast.info(
              err.response.data.message,
              "Unable to approve withdrawal",
              this.$toastPosition
            );
          } else {
            this.$toast.error(
              "An error occured while approving withdrawal",
              "Error",
              this.$toastPosition
            );
          }
        });
    },

    approveWithdrawal() {
      const withdrawal = this.activeData;
      this.$vs.loading();

      let fetch = {
        path: `admin/withdrawal/update-transaction/${withdrawal.id}`,
      };

      this.$store
        .dispatch("postRequest", fetch)
        .then((resp) => {
          this.$vs.loading.close();
          this.$toast.success(
            "Withdrawal Request Approved!",
            "Success",
            this.$toastPosition
          );

          this.getWithdrawalsRequests();
          this.getWithdrawalsTransactions();
        })
        .catch((err) => {
          this.$vs.loading.close();

          if (err.response) {
            this.$toast.info(
              err.response.data.message,
              "Unable to approve withdrawal",
              this.$toastPosition
            );
          } else {
            this.$toast.error(
              "An error occured while approving withdrawal",
              "Error",
              this.$toastPosition
            );
          }
        });
    },
    getWithdrawalsTransactions() {
      this.loading = true;

      let fetch = {
        path: `admin/withdrawal/transactions`,
        data: {},
      };
      this.$store
        .dispatch("getRequest", fetch)
        .then((resp) => {
          this.contents = resp.data;

          this.$vs.loading.close();
          this.loading = false;
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.loading = false;

          if (err.response) {
            this.$toast.info(
              err.response.data.message,
              "Unable to get Withdrawals Transactions",
              this.$toastPosition
            );
          } else {
            this.$toast.info(
              "Something went wrong",
              "Unable to get Withdrawals Transactions",
              this.$toastPosition
            );
          }
        });
    },
    getWithdrawalsRequests() {
      this.loading = true;

      let fetch = {
        path: `admin/withdrawal/requests`,
        data: {},
      };
      this.$store
        .dispatch("getRequest", fetch)
        .then((resp) => {
          this.contentsRequests = resp.data;

          this.$vs.loading.close();
          this.loading = false;
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.loading = false;

          if (err.response) {
            this.$toast.info(
              err.response.data.message,
              "Unable to get Withdrawal Requests",
              this.$toastPosition
            );
          } else {
            this.$toast.info(
              "Something went wrong",
              "Unable to get Withdrawal Requests",
              this.$toastPosition
            );
          }
        });
    },
  },
};
</script>
